import React, { useEffect } from "react";
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "../../components/CustomInput/CustomInput.js";
import PropTypes from "prop-types";
import { useWalletConnector, setNet } from "../../components/WalletConnector.js"
import { useWeb3React } from '@web3-react/core';

import {Navbar, Button, Modal, List } from 'flowbite-react';

// const useStyles = makeStyles(styles);

// const wallets = ["MetaMask", "BSCWallet", "Wallet Connect"];
// const netlists = ["Ethereum", "Bsc"];
const wallets = ["MetaMask", "Wallet Connect"];
const netlists = [ "Bsc"];

// const usedlgStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

function DisconDialog(props) {
  const { logoutWalletConnector} = useWalletConnector();
  const {account} = useWeb3React();
  const { onClose, open, setAccount } = props;

  const handleClose = () => {
    if (account === undefined) {
      setAccount("Connect Wallet");
    }
    onClose();
  };

  const handleDiscon = () => {
    logoutWalletConnector();
    setAccount("Connect Wallet");
    onClose();
  }

  return (
    <>

<Modal

show={open}
onClose={handleClose}
>
<Modal.Header>
  Account Address
</Modal.Header>
<Modal.Body>
<h3 className="text-xl font-medium text-gray-900 dark:text-white">
{account}
        </h3>
  
</Modal.Body>
<Modal.Footer>
      <Button 
      color={'dark'}
      onClick={handleDiscon}
      >
        Disconnect
      </Button>
      <Button
        color={'gray'}
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Modal.Footer>
</Modal>


    </>

  );
}

function WalletSelect(props) {
  
  // const classes = usedlgStyles();

  const { onClose, open, setWallet } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    setWallet(value);
    onClose();
  };
  
  return (
    <>

<Modal

    show={open}
    onClose={handleClose}
  >
    <Modal.Header>
      Connect Wallet
    </Modal.Header>
    <Modal.Body>


    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Connect with one of our available wallet providers or create a new one.</p>
                <ul className="my-4 space-y-3">
                {wallets.map((dwallet) => (
                  <li  onClick={() => handleListItemClick(dwallet)} key={dwallet}>
                        <a  className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                            <img src={dwallet == 'MetaMask' ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/2048px-MetaMask_Fox.svg.png' : 'https://pancakeswap.finance/images/wallets/walletconnect.png'} width={20} alt="" />
                            <span className="flex-1 ml-3 whitespace-nowrap">{dwallet}</span>
                        </a>
                    </li>

                ))}
                </ul>
                <div>
                    <a href="#" className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                        Why do I need to connect with my wallet?</a>
                </div>
      
    </Modal.Body>
  </Modal>
    </>
  );
}

function NetSelect(props) {
  // const classes = usedlgStyles();
  const { loginMetamask, 
    loginWalletConnect, 
    loginBSC} = useWalletConnector();
  const {account} = useWeb3React();
  // console.log("child", account)
  const { onClose, open, setAccount, wallet } = props;

  useEffect(() => {
    if (account !== undefined) {
      // setAccount("Connected");
      setAccount('Disconnect');
    }
  }, [account])

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {

    if(value === 'Ethereum') {
      setNet(0);
      // console.log(value);
    } 
    else if(value === 'Bsc') {
      setNet(1);
      // console.log(value);
    }
      
    // if(wallet === "MetaMask")
    //   loginMetamask();
    // else if(wallet === "BSCWallet")
    //   loginBSC();
    // else if(wallet === "Wallet Connect")
    //   loginWalletConnect();

    if(wallet === "MetaMask")
      loginMetamask();
    else if(wallet === "Wallet Connect")
      loginWalletConnect();

    // console.log(window.localStorage.getItem('walletconnect'))
    onClose();
    // console.log(account)
    // console.log("account info")
    // console.log(web3info)
  };
  
  return (
    <>
    <Modal

show={open}
onClose={handleClose}
>
<Modal.Header>
  Select Network
</Modal.Header>
<Modal.Body>


<p className="text-sm font-normal text-gray-500 dark:text-gray-400">Connect with one of our available wallet providers or create a new one.</p>
            <ul className="my-4 space-y-3">
           {netlists.map((netitem) => (
              <li onClick={() => handleListItemClick(netitem)} key={netitem}>
                    <a  className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                        <span className="flex-1 ml-3 whitespace-nowrap">{netitem}</span>
                    </a>
                </li>

            ))}
            </ul>
            <div>
                <a href="#" className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                    Why do I need to connect with my wallet?</a>
            </div>
  
</Modal.Body>
</Modal>
    </>
  );
}

const buttonuseStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AdminNFavbarLinks() {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [netopen, setNetOpen] = React.useState(false);
  const [opendis, setDisOpen] = React.useState(false);
  const [accountAddr, setAccountAddr] = React.useState("Connect Wallet");
  const buttonstyle = buttonuseStyles();
  const [selwallet, setSelWallet] = React.useState('');

  useEffect(() => {
    if(window.ethereum)
    {
      window.ethereum.on('chainChanged', (chainId) => {
        console.log('Chain Id changed!');
      });

      window.ethereum.on('accountsChanged', (accountad) => {
        console.log('account changed!');
      });
    }
  }, [window.ethereum]);

  // useEffect(() => {
    
  // }, [accountAddr]);

  const handleClickOpen = () => {

    if(accountAddr === "Connect Wallet") {
      setDisOpen(false);
      setOpen(true);
    }
    else {
      setDisOpen(true);
      setOpen(false);
    }
      
  };

  const handleClose = (value) => {
    setOpen(false);
    setNetOpen(true);
  };

  const handleDisconClose = () => {
    setDisOpen(false);
  }

  const handleNetClose = (value) => {
    setNetOpen(false);
  }

  const setWallet = (value) => {
    setSelWallet(value);
  }

  // useEffect(() => {
  //   console.log("parent", accountAddr)
  // }, [accountAddr])
  // console.log(accountAddr)
  return (
    <div>
      <div style={{height:'5px', backgroundColor: '#AF47AB'}}></div>
<Navbar
  // fluid={true}
  // rounded={true}
>
  <Navbar.Brand href="/">
    <img
      src="https://i.ibb.co/1shQSCC/kara.png"
      className="mr-3 h-10 sm:h-10"
      alt="Flowbite Logo"
    />
  </Navbar.Brand>
  <div className="flex md:order-2">
  <Button 
  onClick={handleClickOpen}
  color={'dark'}
  >
    {accountAddr}
  </Button>
  </div>
</Navbar>



      <WalletSelect open={open} onClose={handleClose} setWallet={setWallet} />
      <NetSelect open={netopen} wallet={selwallet} setAccount={setAccountAddr} onClose={handleNetClose} />
      <DisconDialog open={opendis} onClose={handleDisconClose} setAccount={setAccountAddr} />    
    </div>
  );
}
