/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
import { Footer, Icon } from "flowbite-react";
import { AiFillMediumCircle, AiFillTwitterCircle } from "react-icons/ai";
import { SiTelegram } from "react-icons/si";

const useStyles = makeStyles(styles);

export default function Index(props) {
  const classes = useStyles();
  return (

    <Footer container={true}>
  <div className="w-full text-center">
    <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
      <Footer.Brand

href="#"
src="https://i.ibb.co/1shQSCC/kara.png"
className="h-10 sm:h-10"
        alt="Flowbite Logo"
      />
      <Footer.LinkGroup>
        <Footer.Link href="#">
        <AiFillMediumCircle fontSize={30} />
        </Footer.Link>
        <Footer.Link href="#">
        <AiFillTwitterCircle fontSize={30} />
        </Footer.Link>
        <Footer.Link href="#">
          <SiTelegram fontSize={27} />
        </Footer.Link>
      </Footer.LinkGroup>
    </div>
    <Footer.Divider />
    <Footer.Copyright
      href="#"
      by="KaraBloc Token"
      year={2022}
    />
  </div>
</Footer>

//     <footer className="p-4 bg-white shadow md:px-6 md:py-8 dark:bg-gray-900">
//     <div className="sm:flex sm:items-center sm:justify-between">
//         <a href="#" className="flex items-center mb-4 sm:mb-0">
//             <img src="https://i.ibb.co/1shQSCC/kara.png" className="h-12" alt="Flowbite Logo"/>
//         </a>
//         <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
//             <li>
//                 <a href="#" className="mr-4 hover:underline md:mr-6 ">Home</a>
//             </li>
//             <li>
//                 <a href="#" className="mr-4 hover:underline md:mr-6 ">Swap</a>
//             </li>
//             <li>
//                 <a href="#" className="mr-4 hover:underline md:mr-6 ">Locker</a>
//             </li>
//             <li>
//                 <a href="#" className="mr-4 hover:underline md:mr-6 ">Staking</a>
//             </li>
//         </ul>
//     </div>
//     <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
//     <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://flowbite.com/" className="hover:underline">KaraBloc™</a>. All Rights Reserved.
//     </span>
// </footer>
  );
}
